





































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { BonusService } from '@/services/bonus-service';
import bonuseUpload from './compoennts/bonuseUpload.vue';
import { getMoney } from '@/services/utils';

@Component({
  components: {
    bonuseUpload
  }
})
export default class wechatImageText extends Vue {
  public form: any = {
    size: 10,
    current: 1,
    total: 0
  };
  private tableData: any[] = [];
  public loading: Boolean = true;
  public kindshow: Boolean = false;
  private currentRow: any = null;

  @Inject(BonusService) private bonusService!: BonusService;

  public async created(): Promise<void> {
    await this.getList();
  }

  public search() {
    this.form.current = 1;
    this.getList();
  }

  //分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }

  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }

  //获取数据源
  public async getList() {
    this.loading = true;
    const res = await this.bonusService.thisProductPriceList(this.form);
    this.loading = false;
   // let data: any = res.records;
    let data: any = res.records.map((element:any) => {
      if (element.price) {
        element.price = '' + getMoney(element.price)
      }
      return element;
    });
    this.tableData = data;
    this.form.total = res.total;
    this.form.size = res.size;
    this.form.current = res.current;

  }

  //下载
  public async downLoadAll() {
    this.loading = true;
    var data_ = {
      type: '4'
    };
    const res = await this.bonusService.downLoadAll(data_, '产品价格表.xlsx');
    this.loading = false;
  }

  // 重置
  public reset() {
    this.form = {
      size: 10,
      current: 1,
      total: 0
    };
    this.search();
  }

  // public formatPrice(row: any) {
  //   if (Number(row.price)) {
  //     row.price = '' + getMoney(row.price);
  //   }
  //   return row.price;
  // }

}
